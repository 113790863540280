<template>
  <b-container>
    <ResourceList :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
                  :search-query="$route.query.query"
                  can-delete create-to="dash.customers.create" resource="customer" soft-deletes
                  title="Customers" @paginate="onPaginate" @search="onSearch"
                  @delete-many="onDeleteMany">
      <template #buttons>
        <b-dd-item-button :disabled="currentUserCan(permissions.CAN_SEND_PASSWORD_RESET_EMAIL)">Send Password Reset
          Email
        </b-dd-item-button>
      </template>
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-0 text-primary">
              <router-link :to="{ name: 'dash.customers.update', params: { id: item.id } }" class="text-dark">
                #{{ item.id }} {{ item.name }}
              </router-link>
            </p>
            <p class="align-self-end mb-0 text-muted">Created:
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>
          <div class="d-flex flex-row flex-fill flex-column">
            <small class="mb-0 text-muted">{{ item.email }}</small>
            <small class="mb-0 text-muted d-flex flex-column">
              <span>{{ item.invoice_address_line_1 }}</span>
              <span>{{ item.invoice_address_line_2 }}</span>
              <span>{{ item.invoice_county }}</span>
              <span>{{ item.invoice_postcode }}</span>
            </small>
          </div>
          <div class="d-flex flex-row flex-fill mt-2">
            <b-button v-b-popover.bottom="'View this customer.'"
                      :to="{ name: 'dash.customers.view', params: { id: item.id } }" class="mr-1 mb-0"
                      size="sm"
                      variant="primary">
              <b-icon icon="eye"/>
              View
            </b-button>
            <b-button v-b-popover.bottom="'Edit this customer.'"
                      :to="{ name: 'dash.customers.update', params: { id: item.id } }" class="mr-1 mb-0" size="sm"
                      variant="secondary">
              <b-icon icon="pen"/>
              Edit
            </b-button>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"

export default {
  mixins: [currentUser],
  created() {
    this.fetchMany(this.$route.query)
  },
  components: {
    ResourceList,
    FriendlyDate,
  },
  computed: {
    ...mapGetters("customers", ["data", "isDeleting", "isLoading", "pagination"]),
  },

  methods: {
    ...mapActions("customers", ["deleteMany", "fetchMany"]),
    async onDeleteMany({ids, modalId}) {
      await this.deleteMany({ids})
      this.$bvModal.hide(modalId)
      await this.fetchMany(this.$route.query)
    },
    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.replace({name: this.$route.name, query: {...this.$route.query, page}}).catch(() => {
        });
        await this.fetchMany({...this.$route.query, page}).catch(() => {
        });
      }
    },
    async onSearch(query) {
      if (this.$route.query.page !== query) {
        const path = {name: this.$route.name, query: {...this.$route.query, query}};
        await this.$router.push(path).catch(() => {
        });
      }
      await this.fetchMany({...this.$route.query, query}).catch(() => {
      })
    },

  },
}
</script>
<style>
</style>
