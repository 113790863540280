<template>
  <b-container>
    <b-row class="mb-3">
      <b-col cols="6">
        <h1 class="font-weight-bold text-primary">View Customer</h1>
      </b-col>
      <b-col cols="6">
        <div class="d-none d-md-flex flex-row justify-content-end pt-2">
          <b-button v-b-popover.bottom="'Edit this customer.'"
                    :to="{ name: 'dash.customers.update', params: { id: customer.id } }"
                    class="mr-1 mb-0" size="sm"
                    variant="primary">
            <b-icon icon="pen"/>
            Edit Customer
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isLoading">
      <b-col cols="12">
        <p class="text-center">
          <b-spinner variant="secondary"></b-spinner>
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!isLoading">
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')" label="Name">
              <b-form-input v-model="customer.name" :state="validationState(errors.errors, 'name')" disabled/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'email')" label="Email Address">
              <b-form-input v-model="customer.email" :state="validationState(errors.errors, 'email')" disabled
                            type="email"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'contact_number')"
                          label="Contact Number">
              <b-form-input v-model="customer.contact_number" :state="validationState(errors.errors, 'contact_number')"
                            disabled/>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Customer Invoice Address -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" role="button"
                       @click.prevent="toggleCollapse('invoice_address')">
                  <b-icon :icon="collapsed['invoice_address'] ? 'chevron-right' : 'chevron-down'"
                          class="align-self-center cursor-pointer text-muted"
                          font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Customer Invoice Address</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['invoice_address']" class="mt-4">
                <b-col cols="12">
                  <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'invoice_address_line_1')"
                                label="Address Line 1">
                    <b-form-input v-model="customer.invoice_address_line_1"
                                  :state="validationState(errors.errors, 'invoice_address_line_1')"
                                  disabled/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'invoice_address_line_2')"
                                label="Address Line 2">
                    <b-form-input v-model="customer.invoice_address_line_2"
                                  :state="validationState(errors.errors, 'invoice_address_line_2')"
                                  disabled/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'invoice_county')"
                                label="County">
                    <b-form-input v-model="customer.invoice_county"
                                  :state="validationState(errors.errors, 'invoice_county')"
                                  disabled/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'invoice_postcode')"
                                label="Postcode">
                    <b-form-input v-model="customer.invoice_postcode"
                                  :state="validationState(errors.errors, 'invoice_postcode')"
                                  disabled/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Customer Fitting Address -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" role="button"
                       @click.prevent="toggleCollapse('fitting_address')">
                  <b-icon :icon="collapsed['fitting_address'] ? 'chevron-right' : 'chevron-down'"
                          class="align-self-center cursor-pointer text-muted"
                          font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Customer Fitting Address</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['fitting_address']" class="mt-4">
                <b-col cols="12">
                  <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'fitting_address_line_1')"
                                label="Address Line 1">
                    <b-form-input v-model="customer.fitting_address_line_1"
                                  :state="validationState(errors.errors, 'fitting_address_line_1')"
                                  disabled/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'fitting_address_line_2')"
                                label="Address Line 2">
                    <b-form-input v-model="customer.fitting_address_line_2"
                                  :state="validationState(errors.errors, 'fitting_address_line_2')"
                                  disabled/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'fitting_county')"
                                label="County">
                    <b-form-input v-model="customer.fitting_county"
                                  :state="validationState(errors.errors, 'fitting_county')"
                                  disabled/>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'fitting_postcode')"
                                label="Postcode">
                    <b-form-input v-model="customer.fitting_postcode"
                                  :state="validationState(errors.errors, 'fitting_postcode')"
                                  disabled/>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Customer Quotes -->
        <b-row class="mt-4 mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" md="9" role="button"
                       @click.prevent="toggleCollapse('customer_quotes')">
                  <b-icon :icon="collapsed['customer_quotes'] ? 'chevron-right' : 'chevron-down'"
                          class="align-self-center cursor-pointer text-muted"
                          font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Customer Quotes</p>
                  </div>
                </b-col>
                <b-col class="d-flex flex-row" cols="12" md="3">
                  <div class="d-flex flex-column justify-content-end flex-fill">
                    <b-button v-b-popover.bottom="'Create a quote.'"
                              :to="{ name: 'dash.quotes.create', query: { customer_id: customer.id } }"
                              class="mr-1 mb-0" size="sm"
                              variant="secondary">
                      <b-icon icon="file-check"/>
                      Create Quote
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['customer_quotes']" class="mt-4">
                <b-col v-if="!customer.quotes.length" cols="12">
                  <b-row>
                    <b-col cols="12">
                      <b-card class="py-3 text-center">
                        <p class="mb-4">
                          <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                        </p>
                        <p class="font-weight-normal h5 mb-0 text-dark">There are no quotes.</p>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="customer.quotes.length" cols="12">
                  <b-table-simple hover striped>
                    <b-thead>
                      <b-tr>
                        <b-th>#</b-th>
                        <b-th>Order Date</b-th>
                        <b-th>Staff Member</b-th>
                        <b-th>Actions</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(quote, quoteIndex) in customer.quotes" :key="quoteIndex">
                        <b-td>{{ quote.id }}</b-td>
                        <b-td>{{ moment(quote.quote_date).format("Do MMM YYYY") }}</b-td>
                        <b-td>{{ quote.user.name }}</b-td>
                        <b-td>
                          <b-button v-b-popover.bottom="'View more information about this quote.'"
                                    :to="{ name: 'dash.quotes.view', params: { id: quote.id } }" class="mr-1 mb-0"
                                    size="sm"
                                    variant="primary">
                            <b-icon icon="person"/>
                            View
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Customer Orders -->
        <b-row class="mt-4 mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" md="9" role="button"
                       @click.prevent="toggleCollapse('customer_orders')">
                  <b-icon :icon="collapsed['customer_orders'] ? 'chevron-right' : 'chevron-down'"
                          class="align-self-center cursor-pointer text-muted"
                          font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Customer Orders</p>
                  </div>
                </b-col>
                <b-col class="d-flex flex-row" cols="12" md="3">
                  <div class="d-flex flex-column flex-fill">
                    <b-button v-b-popover.bottom="'Create an order.'"
                              :to="{ name: 'dash.orders.create', query: { customer_id: customer.id } }"
                              class="mr-1 mb-0"
                              size="sm"
                              variant="secondary">
                      <b-icon icon="file-check"/>
                      Create Order
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['customer_orders']" class="mt-4">
                <b-col v-if="!customer.orders.length" cols="12">
                  <b-row>
                    <b-col class="text-center" cols="12">
                      <b-card class="py-3 text-center">
                        <p class="mb-4">
                          <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                        </p>
                        <p class="font-weight-normal h5 mb-0 text-dark">There are no orders.</p>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="customer.orders.length" cols="12">
                  <b-table-simple hover striped>
                    <b-thead>
                      <b-tr>
                        <b-th>Order Date</b-th>
                        <b-th>Staff Member</b-th>
                        <b-th>Actions</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(order, orderIndex) in customer.orders" :key="orderIndex">
                        <b-td>{{ moment(order.order_date).format("Do MMM YYYY") }}</b-td>
                        <b-td>{{ order.user.name }}</b-td>
                        <b-td>
                          <b-button v-b-popover.bottom="'View more information about this order.'"
                                    :to="{ name: 'dash.orders.view', query: { id: order.id } }" class="mr-1 mb-0"
                                    size="sm"
                                    variant="primary">
                            <b-icon icon="person"/>
                            View
                          </b-button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Order Notes -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" role="button"
                       @click.prevent="toggleCollapse('customer_notes')">
                  <b-icon :icon="collapsed['customer_notes'] ? 'chevron-right' : 'chevron-down'"
                          class="align-self-center cursor-pointer text-muted"
                          font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Customer Notes</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['customer_notes']" class="mt-4">
                <b-col cols="12">
                  <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'newNote')"
                                label="Create a note for this customer">
                    <b-form-textarea v-model="newNote" :state="validationState(errors.errors, 'newNote')"
                                     placeholder="Insert note here..." size="sm"></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button variant="primary" @click="addNote">Add Note</b-button>
                </b-col>

                <!-- Add New Note Field -->
                <b-col v-if="customer.customer_notes.length" cols="12">
                  <hr/>
                  <b-row v-for="(note, noteInd) in customer.customer_notes" :key="noteInd">
                    <b-col
                      :invalid-feedback="validationInvalidFeedback(errors.errors, 'customer_notes.' + noteInd + '.note')"
                      cols="12">
                      <b-card class="p-4 mt-3" no-body>
                        <p class="note">{{ note.note }}</p>
                        <hr/>
                        <b-row>
                          <b-col class="d-flex flex-row justify-content-start align-items-center" cols="9">
                            <p class="note-created">Left by: {{ note.user.name }}</p>
                          </b-col>
                          <b-col class="d-flex flex-row justify-content-end align-items-center" cols="3">
                            <p class="note-created text-muted">
                              <FriendlyDate :date="note.created_at"></FriendlyDate>
                            </p>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"
import momentMixin from "../../mixins/momentMixin"

export default {
  mixins: [validation, currentUser, momentMixin],
  components: {
    FriendlyDate
  },
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters("customers", ["errors", "isLoading", "isSaving", "single"])
  },
  data() {
    return {
      collapsed: {
        invoice_address: true,
        fitting_address: true,
        customer_notes: true,
        customer_orders: true,
        customer_quotes: true,
      },
      newNote: null,
      users: [],
      customer: {
        name: null,
        contact_number: null,
        address_line_1: null,
        address_line_2: null,
        county: null,
        postcode: null,
        email: null,
        group: null,
        email_confirmation: null,
        customer_notes: [],
        orders: [],
        quotes: []
      }
    }
  },
  methods: {
    ...mapActions("customers", ["fetch", "save"]),

    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    submit() {
      let data = {
        id: this.$route.params.id,
        name: this.customer.name,
        email: this.customer.email,
        email_confirmation: this.customer.email_confirmation,
        contact_number: this.customer.contact_number,
        invoice_address_line_1: this.customer.invoice_address_line_1,
        invoice_address_line_2: this.customer.invoice_address_line_2,
        invoice_county: this.customer.invoice_county,
        invoice_postcode: this.customer.invoice_postcode,
        fitting_address_line_1: this.customer.fitting_address_line_1,
        fitting_address_line_2: this.customer.fitting_address_line_2,
        fitting_county: this.customer.fitting_county,
        fitting_postcode: this.customer.fitting_postcode,
        customer_notes: this.customer.customer_notes
      }

      this.save(data).then(response => {
        if ([200, 201].includes(response.status))
          this.$router.push({
            name: "dash.customers.list",
            params: {
              notifications: [
                {
                  variant: "success",
                  title: `Customer #${response.data.id}`,
                  subtitle: `${response.data.name}`,
                  text: `You have ${!this.$route.params.id ? "created a new" : "updated an existing"} customer.`,
                  timeout: 5000
                }
              ]
            }
          })
      })
    },
    addNote() {
      window.axios
        .post(`/customers/${this.$route.params.id}/add-note`, {
          note: this.newNote
        })
        .then(response => {
          this.customer.customer_notes.unshift(response.data)
        })
    },
    rmCustomerNote(ind) {
      this.customer.customer_notes.splice(ind, 1)
    }
  },
  watch: {
    single() {
      this.customer = this.single
      this.customer.email_confirmation = this.single.email
    }
  }
}
</script>

<style lang="scss">
.alert {
  padding: 0;
}
</style>
