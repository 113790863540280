<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? "Update" : "Create" }} Customer</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center">
            <b-spinner variant="secondary"></b-spinner>
          </p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')" label="Name">
                <b-form-input v-model="customer.name" :disabled="isSaving"
                              :state="validationState(errors.errors, 'name')"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'email')" label="Email Address">
                <b-form-input v-model="customer.email" :disabled="isSaving"
                              :state="validationState(errors.errors, 'email')"
                              type="email"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'contact_number')"
                            label="Contact Number">
                <b-form-input v-model="customer.contact_number" :disabled="isSaving"
                              :state="validationState(errors.errors, 'contact_number')"/>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Customer Invoice Address -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" role="button"
                         @click.prevent="toggleCollapse('invoice_address')">
                    <b-icon :icon="collapsed['invoice_address'] ? 'chevron-right' : 'chevron-down'"
                            class="align-self-center cursor-pointer text-muted"
                            font-scale="1.5"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Customer Invoice Address</p>
                    </div>
                    <div v-if="!addressesAreEqual" class="text-danger">
                      <fa-icon icon="flag"/>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['invoice_address']" class="mt-4">
                  <b-col cols="12">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'invoice_address_line_1')"
                                  label="Address Line 1">
                      <b-form-input v-model="customer.invoice_address_line_1"
                                    :state="validationState(errors.errors, 'invoice_address_line_1')"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'invoice_address_line_2')"
                                  label="Address Line 2">
                      <b-form-input v-model="customer.invoice_address_line_2"
                                    :state="validationState(errors.errors, 'invoice_address_line_2')"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'invoice_county')"
                                  label="County">
                      <b-form-input v-model="customer.invoice_county"
                                    :state="validationState(errors.errors, 'invoice_county')"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'invoice_postcode')"
                                  label="Postcode">
                      <b-form-input v-model="customer.invoice_postcode"
                                    :state="validationState(errors.errors, 'invoice_postcode')"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <small v-if="
								validationState(errors.errors, 'invoice_address_line_1') === false ||
								validationState(errors.errors, 'invoice_county') === false ||
								validationState(errors.errors, 'invoice_postcode') === false
							" class="text-danger">Please ensure the invoice address fields are filled in correctly.</small>
            </b-col>
          </b-row>

          <!-- Customer Fitting Address -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" role="button"
                         @click.prevent="toggleCollapse('fitting_address')">
                    <b-icon :icon="collapsed['fitting_address'] ? 'chevron-right' : 'chevron-down'"
                            class="align-self-center cursor-pointer text-muted"
                            font-scale="1.5"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Customer Fitting Address</p>
                    </div>
                    <div v-if="!addressesAreEqual" class="text-danger">
                      <fa-icon icon="flag"/>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['fitting_address']" class="mt-4">
                  <b-col cols="12">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'fitting_address_line_1')"
                                  label="Address Line 1">
                      <b-form-input v-model="customer.fitting_address_line_1"
                                    :state="validationState(errors.errors, 'fitting_address_line_1')"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'fitting_address_line_2')"
                                  label="Address Line 2">
                      <b-form-input v-model="customer.fitting_address_line_2"
                                    :state="validationState(errors.errors, 'fitting_address_line_2')"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'fitting_county')"
                                  label="County">
                      <b-form-input v-model="customer.fitting_county"
                                    :state="validationState(errors.errors, 'fitting_county')"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'fitting_postcode')"
                                  label="Postcode">
                      <b-form-input v-model="customer.fitting_postcode"
                                    :state="validationState(errors.errors, 'fitting_postcode')"/>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button v-if="!addressesAreEqual" size="sm" variant="primary" @click="copyInvoiceAddress">Copy
                      Invoice Address
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
              <small v-if="
								validationState(errors.errors, 'fitting_address_line_1') === false ||
								validationState(errors.errors, 'fitting_county') === false ||
								validationState(errors.errors, 'fitting_postcode') === false
							" class="text-danger">Please ensure the fitting address fields are filled in correctly.</small>
            </b-col>
          </b-row>

          <!-- Customer Notes -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="6" role="button"
                         @click.prevent="toggleCollapse('customer_notes')">
                    <b-icon :icon="collapsed['customer_notes'] ? 'chevron-right' : 'chevron-down'"
                            class="align-self-center cursor-pointer text-muted"
                            font-scale="1.5"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Customer Notes</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['customer_notes']" class="mt-4">
                  <b-col cols="12">
                    <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'newNote')"
                                  label="Create a note for this customer">
                      <b-form-textarea v-model="newNote" :state="validationState(errors.errors, 'newNote')"
                                       placeholder="" size="sm"></b-form-textarea>
                    </b-form-group>
                  </b-col>

                  <!-- Add New Note Field -->
                  <b-col v-if="customer.customer_notes.length" cols="12">
                    <hr/>
                    <b-row v-for="(note, noteInd) in customer.customer_notes" :key="noteInd">
                      <!-- List Of Notes -->
                      <b-col
                        :invalid-feedback="validationInvalidFeedback(errors.errors, 'customer_notes.' + noteInd + '.note')"
                        cols="12">
                        <b-card class="p-4 mt-3" no-body>
                          <p class="note">{{ note.note }}</p>
                          <hr/>
                          <b-row>
                            <b-col class="d-flex flex-row justify-content-start align-items-center" cols="9">
                              <p class="note-created">Left by: {{ note.user.name }}</p>
                            </b-col>
                            <b-col class="d-flex flex-row justify-content-end align-items-center" cols="3">
                              <p class="note-created text-muted">
                                <FriendlyDate :date="note.created_at"></FriendlyDate>
                              </p>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="primary">
                <b-spinner v-if="isSaving" small></b-spinner>
                <span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"

export default {
  mixins: [validation],
  components: {
    FriendlyDate
  },
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters("customers", ["errors", "isLoading", "isSaving", "single"]),

    formattedCustomerOrders() {
      return this.customer.orders.map(function (order) {
        return {
          "Order ID": order.id,
          "Order Date": order.order_date,
          Quote: order.quote ? "Yes" : "No",
          "Staff Member": order.user_id
        }
      })
    },

    addressesAreEqual() {
      return (
        this.customer.invoice_address_line_1 === this.customer.fitting_address_line_1 &&
        this.customer.invoice_address_line_2 === this.customer.fitting_address_line_2 &&
        this.customer.invoice_county === this.customer.fitting_county &&
        this.customer.invoice_postcode === this.customer.fitting_postcode
      )
    }
  },

  data() {
    return {
      collapsed: {
        invoice_address: true,
        fitting_address: true,
        customer_notes: true
      },

      newNote: null,

      sameAddressCheck: null,

      customer: {
        name: null,
        contact_number: null,
        invoice_address_line_1: null,
        invoice_address_line_2: null,
        invoice_county: null,
        invoice_postcode: null,
        fitting_address_line_1: null,
        fitting_address_line_2: null,
        fitting_county: null,
        fitting_postcode: null,
        email: null,
        group: null,
        email_confirmation: null,
        customer_notes: [],
        orders: [],
        users: []
      }
    }
  },
  methods: {
    ...mapActions("customers", ["fetch", "save"]),

    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    getBoxStatus() {
      let boxStatus = ""
      let fittingAddress = [this.customer.fitting_address_line_1, this.customer.fitting_address_line_2, this.customer.fitting_county, this.customer.fitting_postcode]
      let invoiceAddress = [this.customer.invoice_address_line_1, this.customer.invoice_address_line_2, this.customer.invoice_county, this.customer.invoice_postcode]
      if (fittingAddress.join("") === invoiceAddress.join("")) {
        boxStatus = "Same"
      } else {
        boxStatus = "Different"
      }

      this.sameAddressCheck = boxStatus
    },

    copyInvoiceAddress() {
      this.customer.fitting_address_line_1 = this.customer.invoice_address_line_1
      this.customer.fitting_address_line_2 = this.customer.invoice_address_line_2
      this.customer.fitting_county = this.customer.invoice_county
      this.customer.fitting_postcode = this.customer.invoice_postcode
    },

    submit() {
      let data = {
        id: this.$route.params.id,
        name: this.customer.name,
        email: this.customer.email,
        contact_number: this.customer.contact_number,
        invoice_address_line_1: this.customer.invoice_address_line_1,
        invoice_address_line_2: this.customer.invoice_address_line_2,
        invoice_county: this.customer.invoice_county,
        invoice_postcode: this.customer.invoice_postcode,
        fitting_address_line_1: this.customer.fitting_address_line_1,
        fitting_address_line_2: this.customer.fitting_address_line_2,
        fitting_county: this.customer.fitting_county,
        fitting_postcode: this.customer.fitting_postcode,
        newNote: this.newNote
      }

      this.save(data).then(response => {
        if ([200, 201].includes(response.status))
          this.$router.push({
            name: "dash.customers.list",
            params: {
              notifications: [
                {
                  variant: "success",
                  title: `Customer #${response.data.id}`,
                  subtitle: `${response.data.name}`,
                  text: `You have ${!this.$route.params.id ? "created a new" : "updated an existing"} customer.`,
                  timeout: 5000
                }
              ]
            }
          })
      })
    },
    addCustomerNote() {
      this.customer.customer_notes.push({
        id: null,
        user_id: null,
        note: null
      })
    },
    rmCustomerNote(ind) {
      this.customer.customer_notes.splice(ind, 1)
    }
  },
  watch: {
    single() {
      this.customer = this.single
      this.getBoxStatus()
    }
  }
}
</script>

<style lang="scss">
.alert {
  padding: 0;
}
</style>
