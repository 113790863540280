<template>
  <router-view />
</template>

<script>
import customerModule from '../../store/customers'

export default {
  beforeCreate () {
    this.$store.registerModule('customers', customerModule)
  },
  destroyed () {
    this.$store.unregisterModule('customers')
  }
}
</script>
